import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NotFoundIllustration from './../components/illustrations/NotFound'
import { Helmet } from 'react-helmet'

const Main = styled.main`
	text-align: center;
	padding: 3rem 1rem;

	& svg {
		max-width: 25rem;
		margin: 1rem auto;
	}

	& p,
	a {
		font-size: 1.5rem;
	}
`

const NotFoundPage = () => {
	return (
		<>
			<Helmet title='Page not found' />
			<Main>
				<h1>Aw, Snap!</h1>
				<NotFoundIllustration />
				<p>Sorry, I couldn't find this page.</p>
				<Link to='/'>Go Back Home</Link>
			</Main>
		</>
	)
}

export default NotFoundPage
