import React from 'react'

const NotFoundIllustration = (prps) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1293.07 603.986'>
			<g transform='translate(204.288 379.137)'>
				<path fill='#5d21d1' d='M55.346 0v221.146H0V0z' />
				<path fill='#fff' d='M41.061 18.838v102.973H11.348V18.838z' />
				<path fill='#ffb000' d='M41.054 126.12v9.64H11.341v-9.64z' />
				<circle
					cx='10.433'
					cy='10.433'
					r='10.433'
					transform='translate(15.2 172.726)'
					fill='none'
					stroke='#fff'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
				<path
					fill='none'
					stroke='#ffb000'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
					d='M37.66 43.326H15.581M37.66 54.667H15.581M37.66 66.008H15.581'
				/>
			</g>
			<g transform='translate(259.405 379.366)'>
				<path fill='#5d21d1' d='M55.346 0v221.146H0V0z' />
				<path fill='#fff' d='M41.061 18.83v102.973H11.348V18.83z' />
				<path fill='#2e186a' d='M41.061 126.12v9.64H11.348v-9.64z' />
				<circle
					cx='10.433'
					cy='10.433'
					r='10.433'
					transform='translate(15.2 172.726)'
					fill='none'
					stroke='#fff'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
				<path
					fill='none'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
					d='M37.317 90.504H15.238M37.317 31.986H15.238M37.317 101.845H15.238M37.317 113.185H15.238'
				/>
			</g>
			<g transform='translate(149.72 379.366)'>
				<path fill='#5d21d1' d='M55.346 0v221.146H0V0z' />
				<path fill='#fff' d='M41.061 18.83v102.973H11.348V18.83z' />
				<path fill='#ffb000' d='M41.061 126.112v9.64H11.348v-9.64z' />
				<circle
					cx='10.433'
					cy='10.433'
					r='10.433'
					transform='translate(15.2 172.726)'
					fill='none'
					stroke='#fff'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
				<path
					fill='none'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
					d='M37.317 90.504H15.238M37.317 31.986H15.238M37.317 101.845H15.238M37.317 113.185H15.238'
				/>
			</g>
			<g
				fill='#fff'
				stroke='#2e186a'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='6'>
				<path d='M266.545 308.347a36.127 36.127 0 00-8.145-24.527c-9.472-11.554-9.587-18.883-14.925-24.413s-13.812-3-6.185 15.749 10.921 26.456 10.921 26.456zM222.881 281.891c.824 0 52.745-2.646 58.93 48.81' />
				<path d='M258.009 384.635s-6.437-37.988-14.872-51.174-27.272-12.2-27.272-12.2-47.895-42.457-54.827-50.129-4.278-14.986 8.115-6.46 49.643 35.549 49.643 35.549 62.1-21.026 72.193 38.621l11.44 35.4z' />
				<path d='M215.049 319.383s-24.6-54.644-28.279-64.94 1.3-15.726 9.663-2.067 27.4 45.309 27.4 45.309' />
				<path d='M212.722 315.013s-1.96-46.873-1.952-55.956c0-14.971 8.992-19.226 11.31.953 1.525 13.476 3.257 38.316 3.257 38.316M919.183 308.348a36.1 36.1 0 018.137-24.527c9.48-11.547 9.579-18.883 14.94-24.4s13.8-3 6.177 15.749-10.952 26.449-10.952 26.449zM962.8 281.891c-.824 0-52.753-2.646-58.93 48.81' />
				<path d='M927.695 384.636s6.437-37.988 14.872-51.174 27.273-12.2 27.273-12.2 47.879-42.464 54.827-50.137 4.278-14.986-8.115-6.46-49.641 35.547-49.641 35.547-62.1-21.026-72.193 38.621l-11.44 35.4z' />
				<path d='M970.655 319.383s24.6-54.644 28.279-64.94-1.3-15.726-9.655-2.067-27.387 45.31-27.387 45.31' />
				<path d='M972.982 315.013s1.96-46.873 1.952-55.956c0-14.971-8.992-19.226-11.31.953-1.525 13.476-3.249 38.316-3.249 38.316' />
			</g>
			<path
				d='M941.956 364.234c1.014-11.669-.511-16.29-.511-16.29-26.83 2.837-48.345-22.788-48.345-22.788s-2.563 2.9-9.785 12.2-20.935-1.2-29.034 12.157c-6.544 10.8-29.743 67.4-38.415 88.712-26.334-78.256-60.189-118.021-115.42-128.027-7.2-43.616-38.278-98.947-161.98-89.291-76.875 13.857-100.335 53.241-95.019 94.828h-.366s-49.771-.663-77.653 82.168c-8.168-19.219-16.458-38.026-20.02-43.914-8.107-13.362-35.784-7.36-43.014-16.641s-9.792-12.2-9.792-12.2-21.507 25.671-48.367 22.8c0 0-1.525 4.622-.5 16.29s-13.171 13-10.006 28.264 53.131 152.115 53.131 152.115c4.713 12.721 10.936 52.524 36.76 53.942a1.029 1.029 0 00.9.664c40.947-.114 541.644.305 541.644.305s.046-.542.1-1.563c22.308-5.262 28.089-41.351 32.535-53.348 0 0 49.977-136.858 53.134-152.119s-10.991-16.595-9.977-28.264z'
				fill='#ffb000'
			/>
			<path
				d='M942.185 362.373c1.914-12.2-.45-16.778-.45-16.778-24.512 5.3-47.162-22.567-47.162-22.567s-3.051 2.707-10.357 12.965c-2.952 4.141-5.956 4.576-9.015 5.43 10.181 33.114 57.87 37.69 74.488 38.095-1.258-7.162-8.953-7.879-7.504-17.145zM243.427 362.464c-1.914-12.2.541-16.839.541-16.839 24.519 5.3 47.17-22.567 47.17-22.567s3.691.412 11.02 10.677c2.951 4.141 8.023 7.627 11.081 8.473-10.174 33.107-61.127 37.6-77.737 38 1.251-7.196 9.374-8.47 7.925-17.744zM443.166 313.151c-14.9 3.051-30.346 10.875-44.005 26.029 12.713 18.4 57.107 80.963 138.955 94.264a8.3 8.3 0 00-.358-1.67c-45.363-18.616-89.795-70.408-94.592-118.623zM700.393 309.864c2.837 16.946 2.059 32.161 1.678 41.145-1.258 29.7-14.582 50.419-22.964 65.2l-.595 15.207c34.594-8.153 61.775-72.216 66.427-103.721-12.278-6.731-14.474-11.745-44.546-17.831z'
				fill='#2e186a'
			/>
			<g
				fill='none'
				stroke='#2e186a'
				stroke-linecap='round'
				stroke-linejoin='round'>
				<path
					d='M532.305 415.019s32.855 56.72 36.28 92.54M674.007 411.541s-15.383 56.032-11.623 91.808'
					stroke-width='8'
				/>
				<path
					d='M363.393 399.888c.343 1.045 36.8 78.446 36.8 78.446M817.759 439.897l-24.985 43.479M471.194 539.194s-3.051 62.1-35.509 60.646'
					stroke-width='6'
				/>
			</g>
			<path
				d='M655.664 599.727C648.899 547.31 641.7 469.794 652.758 437c17.457-51.662 10.677-116.5-1.114-125.891s-142.006-7.573-142.006-7.573l-23.406 8.061s89.116 82.794 115.5 233.867c4.141 23.688 6.94 42.3 8.8 56.909'
				fill='#fff'
			/>
			<path
				d='M529.902 259.568l-1.883 33.05-19.448 13.4s50.938 35.273 96.491 87.957c0 0 27.951-19.76 34.487-82.367l-14.658-2.288-70.164-41.565z'
				fill='#fff'
				stroke='#2e186a'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='6'
			/>
			<g transform='translate(480.16)'>
				<path
					d='M27.706 199.25s-30.056 4.149-21.53 30.918 40 7.016 40 7.016l-8.908-38.43z'
					fill='#fff'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
				<path
					d='M99.209 92.279c7.863-1.6 61.874-11.508 73.1 33.923s10.624 97.116 8.252 120.964-107.755 25.389-113.887 24.008-13.957-7.568-13.957-7.568l-22.384-69.134S14.73 109.561 99.209 92.279z'
					fill='#fff'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
				<path
					d='M40.157 192.683c5.613 4.492 22.46 32.2 27.311 45.477 0 0 32.092-15.428 79.865-21.781 0 0 26.151-2.547 36.912-.625 0 0 3.279 48.657-15.009 76.2s-63.819 25.823-86.515 13.24-26.99-19.364-33.686-34.907-26.4-74.115-26.4-74.115z'
					fill='#968bb4'
				/>
				<path
					d='M27.687 204.627s-16.663-20.628-24.725-43.144c-7.627-21.164-.2-45.134 18.525-57.2 15.665-10.09 28.691-5.117 28.691-5.117s-21.034-28.416 2.357-58.4a48.184 48.184 0 0133.74-18.426c32.359-2.784 45.447 23.772 45.447 23.772s27.279-68.474 102.362-38.54c7.573 3.051 16.816 12.813 21.484 19.585 28.31 41.077-42.914 101.906-82.565 110.677-43.547 9.625-70.164 5.2-85.585-4.1 11.28 5.213 6.948 73.585-59.731 70.893z'
					fill='#2e186a'
				/>
				<path
					d='M141.986 186.041l9.579 41.6-12.317 2.8'
					fill='none'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='8'
				/>
				<ellipse
					cx='4.278'
					cy='6.727'
					rx='4.278'
					ry='6.727'
					transform='rotate(-6.28 1779.402 -1278.438)'
					fill='#2e186a'
				/>
				<ellipse
					cx='4.278'
					cy='6.727'
					rx='4.278'
					ry='6.727'
					transform='rotate(-6.28 1783.993 -911.874)'
					fill='#2e186a'
				/>
				<path
					d='M101.84 187.924s17.4-1.037 17.175-12.66M151.908 158.63s11.44-4.507 15.5 4.934'
					fill='none'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='8'
				/>
				<path
					d='M138.211 248.983a81.04 81.04 0 01-18.525 1.228c-3.813-.16-6.7 2.2-5.575 4.576 1.975 4.179 6.7 9.152 17.907 7.848 11.043-1.312 13.247-6.414 13.224-10.342-.015-2.365-3.576-3.989-7.031-3.31z'
					fill='none'
					stroke='#2e186a'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
			</g>
			<g>
				<g transform='translate(644.392 539.637)'>
					<path fill='#2e186a' d='M234.401 55.346H0V0h234.401z' />
					<path
						fill='#fff'
						d='M228.206 44.222l-102.972.485-.14-29.712 102.972-.486z'
					/>
					<path
						fill='#ffb000'
						d='M120.922 44.728l-9.64.045-.14-29.712 9.64-.046z'
					/>
					<circle
						cx='10.433'
						cy='10.433'
						r='10.433'
						transform='translate(39.132 20.607)'
						fill='none'
						stroke='#fff'
						stroke-linecap='round'
						stroke-linejoin='round'
						stroke-width='6'
					/>
					<path
						fill='none'
						stroke='#ffb000'
						stroke-linecap='round'
						stroke-linejoin='round'
						stroke-width='6'
						d='M203.674 40.97l-.107-22.079M192.334 41.023l-.107-22.079M180.993 41.077l-.107-22.079'
					/>
				</g>
				<g transform='translate(682.197 487.746)'>
					<path fill='#2e186a' d='M221.146 58.16H0V0h221.146z' />
					<path fill='#fff' d='M202.317 41.061H99.344V11.348h102.973z' />
					<path fill='#ffb000' d='M95.027 41.061h-9.64V11.348h9.64z' />
					<circle
						cx='10.433'
						cy='10.433'
						r='10.433'
						transform='translate(27.555 15.2)'
						fill='none'
						stroke='#fff'
						stroke-linecap='round'
						stroke-linejoin='round'
						stroke-width='6'
					/>
					<path
						fill='none'
						stroke='#ffb000'
						stroke-linecap='round'
						stroke-linejoin='round'
						stroke-width='6'
						d='M130.642 37.317V15.238M189.161 37.317V15.238M119.302 37.317V15.238M107.961 37.317V15.238'
					/>
				</g>
			</g>
			<g>
				<path fill='#5d21d1' d='M861.527 407.027h273.907v193.195H861.527z' />
				<path
					d='M1015.209 405.189v196.139'
					fill='#2e186a'
					stroke='#fff'
					stroke-linejoin='round'
					stroke-width='6'
				/>
				<path
					fill='#2e186a'
					stroke='#fff'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
					d='M978.335 428.427h123.939M1053.319 497.836h79.629M965.942 562.593h155.238M987.632 480.486h-80.246M861.832 450.117h48.65M861.527 539.355h95.118'
				/>
				<path
					d='M861.116 450.422c-20.454 8.054.618 21.53.618 21.53s5.27-15.253 32.222-21.469zM1136.234 497.516c20.447 8.054-.625 21.537-.625 21.537s-5.262-15.253-32.222-21.476zM933.42 539.492s-47.46 8.954-46.285 36.874c0 0-38.041-13.956-25.167-36.874z'
					fill='#5d21d1'
					stroke='#fff'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='6'
				/>
			</g>
			<path
				fill='none'
				stroke='#2e186a'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='8'
				d='M4 599.986h1285.07'
			/>
		</svg>
	)
}

export default NotFoundIllustration
